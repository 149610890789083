<template>
  <div class="projects app py-5 table-orders">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-12">
            <h3>{{ $t('active-projects-label') }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <table v-if="projects.length" id="active-projects" class="table table-striped table-compact table-responsive-lg dataTable no-footer">
              <thead>
              <tr role="row">
                <th class="sorting_asc" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('project-label') }}</th>
                <th class="sorting" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('start-label') }}</th>
                <th class="sorting" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('amount-label') }}</th>
                <th class="sorting" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('type-label') }}</th>
                <th class="sorting" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('field-label') }}</th>
                <th class="sorting text-center" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('installing-label') }}</th>
                <th class="sorting text-center" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('fridge-label') }}</th>
                <th class="sorting text-center" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('payment-label') }}</th>
                <th class="sorting text-center" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('status-label') }}</th>
                <th class="sorting text-center" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1"></th>
              </tr>
              </thead>
              <tbody>
              <tr role="row" v-for="item in projects" :key="item.id">
                <td class="text-dark col-project" :data-th="$t('project-label')">{{ item.project_name }}</td>
                <td class="col-project" :data-th="$t('start-label')">{{ createDate(item.date) }}</td>
                <td class="col-project" :data-th="$t('amount-label')">{{ item.total_area }} m<sup>2</sup></td>
                <td class="col-project" :data-th="$t('type-label')">{{ $t(item.roll_thickness) }}</td>
                <td class="col-project" :data-th="$t('field-label')">{{ item.field }}</td>
                <td class="text-xl-center col-project" :data-th="$t('installing-label')">
                  <i class="icon-check text-success font-size-12" v-if="item.roll_options && item.roll_options.includes('installing-label')"></i>
                  <i class="icon-cross-sm text-danger font-size-12" v-if="item.roll_options && !item.roll_options.includes('installing-label')"></i>
                </td>
                <td class="text-xl-center col-project" :data-th="$t('fridge-label')">
                  <i class="icon-check text-success font-size-12" v-if="item.roll_options && item.roll_options.includes('cooler-truck-label')"></i>
                  <i class="icon-cross-sm text-danger font-size-12" v-if="item.roll_options && !item.roll_options.includes('cooler-truck-label')"></i>
                </td>
                <td class="text-xl-center col-project" :data-th="$t('payment-label')">
                  <i class="icon-cross-sm text-danger font-size-12" v-if="item.payment_status == 'unpaid-label'"></i>
                  <i class="icon-clock text-yellow font-size-12" v-if="item.payment_status == 'waiting-for-payment-label'"></i>
                  <i class="icon-check text-success font-size-12" v-if="item.payment_status == 'paid-label'"></i>
                </td>
                <td class="text-center col-action">
                  <div class="status status-warning" v-if="item.status == 'planned-label'"></div>
                  <div class="status status-warning" v-if="item.status == 'cutting-started-label'"></div>
                  <div class="status status-success" v-if="item.status == 'completed-label'"></div>
                </td>
                <td>
                  <div class="badge-group">
                    <span class="badge text-green">{{ item.completed_left_trucks }} <i class="icon-truck"></i></span>
                    <span class="badge text-orange">{{ item.arrived_trucks }} <i class="icon-truck"></i></span>
                    <span class="badge">{{ item.planned_trucks }} <i class="icon-truck"></i></span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <span class="text-center d-block text-muted" v-if="!projects.length">{{ $t('loading-label')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {logout} from "@/main";

const axios = require('axios').default;
import moment from 'moment'

export default {
  name: 'projects',
  data(){
    return {
      projects: {}
    }
  },
  methods:{
    getProjects(){
      const self = this
      axios.get(process.env.VUE_APP_API_URL+'projects', {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        self.projects = response.data.data
      }).catch(function (error) {
        if(error.response.status === 401){
          logout();
          return false;
        }
        self.$swal(error.message);
      });
    },
    createDate(date) {
      return moment(date).format('MM.DD.YYYY')
    }
  },
  mounted() {
    this.getProjects();
  }
}
</script>
