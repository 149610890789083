<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            @click="close">
          <i class="icon-close font-size-11"></i>
        </button>
      </div>

      <section class="modal-body">
        <slot name="body">
          <div class="row">
            <div class="col-12 pb-3">
              <h4>{{ addressId ? $t('edit-address-label') : $t('add-address-label') }}</h4>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group" :class="{ 'form-group-error': $v.shipping_city.$error }">
                <div class="input-box">
                  <label>{{ $t('shipping-city-label') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="shipping_city" class="form-control">
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label')}}</div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group" :class="{ 'form-group-error': $v.shipping_zip.$error }">
                <div class="input-box">
                  <label>{{ $t('shipping-zip-label') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="shipping_zip" class="form-control">
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label')}}</div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group" :class="{ 'form-group-error': $v.shipping_country.$error }">
                <div class="select-box compact">
                  <label>{{$t('shipping-country-label')}}</label>
                  <Select2 id="shipping_country" v-model="shipping_country" :options="countries" />
                </div>
                <div class="error">{{ $t('please-select-label') }}</div>
              </div>
            </div>
            <div class="col-12 col-md-6 border-bottom">
              <div class="form-group" :class="{ 'form-group-error': $v.shipping_street.$error }">
                <div class="input-box">
                  <label>{{ $t('shipping-address-label') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="shipping_street" class="form-control">
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label')}}</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <button role="button" @click="saveAddress()" class="btn btn-primary">{{ $t('save-label') }}</button>
              </div>
            </div>
          </div>
        </slot>
      </section>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
const axios = require('axios').default;

export default {
  name: 'Modal',
  data(){
    return {
      addressId:  null,
      shipping_zip: this.$parent.currentAddress && this.$parent.currentAddress.shipping_zip,
      shipping_city: this.$parent.currentAddress && this.$parent.currentAddress.shipping_city,
      shipping_country: this.$parent.currentAddress && this.$parent.currentAddress.shipping_country,
      shipping_street: this.$parent.currentAddress && this.$parent.currentAddress.shipping_street,
      countries: [],
    }
  },
  validations: {
    shipping_zip: {
      required
    },
    shipping_city: {
      required
    },
    shipping_country: {
      required
    },
    shipping_street: {
      required
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    getCountries(){
      const self = this;

      axios.get(process.env.VUE_APP_API_URL+'constants/countries', {
        device_name: process.env.VUE_APP_DEVICE,
        language: self.$i18n.locale,
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        response.data.data.forEach(country => self.countries.push({id : country.id, text: country.text}));
      }).catch(function (error) {
        self.$swal(error.message);
      });
    },
    saveAddress(){
      const self = this
      const config = { headers: {'Authorization': `Bearer ${localStorage.token}`} };

      self.$v.$touch()
      if (!self.$v.$invalid) {
        self.addressId ?
            // EDIT ADDRESS
            axios.put(process.env.VUE_APP_API_URL+'addresses/'+self.addressId+'?shipping_city='+self.shipping_city+'&shipping_country='+self.shipping_country+'&shipping_zip='+self.shipping_zip+'&shipping_street='+self.shipping_street, {},config).then(function (response) {
              if(response.status == 200){
                self.$swal(self.$i18n.t('successfully-saved-label'));
                self.close()
                self.$parent.getProfile()
              }
            }).catch(function (error) {
              self.$swal(error.message);
            }) :
            // CREATE ADDRESS
            axios.post(process.env.VUE_APP_API_URL+'addresses/?shipping_city='+self.shipping_city+'&shipping_country='+self.shipping_country+'&shipping_zip='+self.shipping_zip+'&shipping_street='+self.shipping_street, {},config).then(function (response) {
              if(response.status == 201){
                self.$swal(self.$i18n.t('successfully-saved-label'));
                self.close()
                self.$parent.getProfile()
              }
            }).catch(function (error) {
              self.$swal(error.message);
            })
      }
    }
  },
  mounted() {
    this.getCountries();
    if(this.$parent.currentAddress){
      this.addressId = this.$parent.currentAddress.id
    }
  }
};
</script>
