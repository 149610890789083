<template>
  <div class="create-order app py-5">
    <div class="row" v-show="this.season_closed === true">
        <div class="col-12">
          <div class="text-center p-3 font-size-32 alert alert-danger">Season closed!</div>
        </div>
    </div>
    <div class="row" v-show="this.season_closed === false">
      <div class="col-12">
        <div class="row">
          <div class="col-12 create-order-content">
            <ul role="tablist" class="nav nav-tabs">
              <li class="nav-item text-center text-md-left" :class="currentStep == 0 ? 'active' : currentStep > 0 ? 'past-item' : ''"><div class="nav-link" :class="currentStep == 0 ? 'active' : ''"> <span class="d-md-none">1/6</span> {{$t('select-amount-label')}}</div></li>
              <li class="nav-item text-center text-md-left" :class="currentStep == 1 ? 'active' : currentStep > 1 ? 'past-item' : ''"><div class="nav-link" :class="currentStep == 1 ? 'active' : ''"> <span class="d-md-none">2/6</span> {{$t('select-date-label')}}</div></li>
              <li class="nav-item text-center text-md-left" :class="currentStep == 2 ? 'active' : currentStep > 2 ? 'past-item' : ''"><div class="nav-link" :class="currentStep == 2 ? 'active' : ''"> <span class="d-md-none">3/6</span> {{$t('delivery-label')}}</div></li>
              <li class="nav-item text-center text-md-left" :class="!enablePayment ? 'd-none' :  currentStep == 3 ? 'active' : currentStep > 3 ? 'past-item' : ''"><div class="nav-link" :class="currentStep == 3 ? 'active' : ''"> <span class="d-md-none">4/6</span> {{$t('payment-label')}}</div></li>
              <li class="nav-item text-center text-md-left" :class="currentStep == 4 ? 'active' : currentStep > 4 ? 'past-item' : ''"><div class="nav-link" :class="currentStep == 4 ? 'active' : ''"> <span class="d-md-none">5/6</span> {{$t('review-label')}}</div></li>
              <li class="nav-item text-center text-md-left" :class="currentStep == 5 ? 'active' : currentStep > 5 ? 'past-item' : ''"><div class="nav-link" :class="currentStep == 5 ? 'active' : ''"> <span class="d-md-none">6/6</span> {{$t('order-complete-label')}}</div></li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-12" v-show="currentStep === 0">
            <Amounts ref="Amounts"/>
          </div>
          <div class="col-12" v-show="currentStep === 1">
            <Delivery ref="Delivery"/>
          </div>
          <div class="col-12" v-show="currentStep === 2">
            <Address ref="Address" :enablePayment="this.enablePayment"/>
          </div>
          <div class="col-12" v-show="currentStep === 3">
            <Payment ref="Payment"/>
          </div>
          <div class="col-12" v-show="currentStep === 4">
            <Review ref="Review"/>
          </div>
          <div class="col-12" v-show="currentStep === 5">
            <ThankYouPage ref="Thank"/>
          </div>
        </div>
      </div>
      <div class="col-12 pt-3" v-show="currentStep != 5">
        <div class="row justify-content-end">
          <div class="col-auto">
            <div class="btn-container">
              <button type="button" role="button" class="btn btn-white mr-3" v-if="currentStep != 0" @click="prevStep">
                {{ $t('back-label') }}
              </button>
              <button type="button" role="button" class="btn btn-primary" @click="nextStep">
                <span v-html="currentStep == 4 ? $t('finish-order-label') : $t('continue-label')"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require('axios').default;

import Amounts from '../components/Orders/Amounts'
import Address from '../components/Orders/Address'
import Payment from '../components/Orders/Payment'
import Review from '../components/Orders/Review'
import Delivery from '../components/Orders/Delivery'
import ThankYouPage from "@/components/Orders/Thank";

export default {
  name: 'create-order',
  props:['type'],
  data: function () {
    return {
      enablePayment: (this.$store.state.user.should_skip_payment_step && this.$store.state.user.payment_type) ? false : true,
      season_closed: false,
      order: {
          client_id: this.$store.state.user.id,
          address_id: null,
          delivery_partner_id: null,
          status: 'pending-label',
          name: this.$store.state.user.name,
          phone: this.$store.state.user.phone,
          email: this.$store.state.user.email,
          website: this.$store.state.user.website,
          billing_country: this.$store.state.user.billing_country,
          billing_city: this.$store.state.user.billing_city,
          billing_street: this.$store.state.user.billing_street,
          billing_zip: this.$store.state.user.billing_zip,
          contact_first_name: this.$store.state.user.contact_first_name,
          contact_last_name: this.$store.state.user.contact_last_name,
          address: {
            shipping_country: null,
            shipping_city: null,
            shipping_street: null,
            shipping_zip: null,
            shipping_address_save: false,
          },
          car: {
            license_plate: null,
            loading_capacity: null,
          },
          payment_type: null,
          payment_status: 'unpaid-label',
          number_of_pallets: null,
          fertilizer: null,
          fertilizer_type: null,
          fertilizer_bags: 0,
          took_by: null,
          took_at: null,
          paid_by: null,
          paid_at: null,
          invoice_number: null,
          roll_type: 'small-roll-label',
          number_of_rolls: null,
          total_area: 0,
          rolls_per_pallet: Number(JSON.parse(window.localStorage.user).pallets),
          grass_type: null,
          delivery_type: null,
          pickup_time: null,
          car_id: null,
        comments:[],
      },
      currentStep: 0
    }
  },
  components: {
    ThankYouPage,
    Delivery,
    Amounts,
    Address,
    Payment,
    Review
  },
  methods:{
    nextStep(){
      if(this.currentStep == 0){
        this.$refs.Amounts.validate();
      }
      else if(this.currentStep == 1){
        this.$refs.Delivery.validate();
      }
      else if(this.currentStep == 2){
        this.$refs.Address.validate();
      }
      else if(this.currentStep == 3){
        this.$refs.Payment.validate();
      }
      else if(this.currentStep == 4){
        this.$refs.Review.validate();
      }
    },
    prevStep(){
      this.currentStep--;
      if(this.currentStep == 3 && !this.enablePayment){
        this.currentStep--;
      }
      window.scrollTo(0,0);
    },
    saveOrder(){
      const self = this

      const config = { headers: {'Authorization': `Bearer ${localStorage.token}`} };

      axios.post(process.env.VUE_APP_API_URL+'orders',
          {order: self.order},
        config
      ).then(function () {
        self.currentStep++;
      }).catch(function (error) {
        self.$swal(error.message);
      });
    }
  },
  beforeMount () {
    this.order.grass_type = this.$props.type+'-label'
  },
  mounted () {
    this.season_closed = JSON.parse(localStorage.info).season_closed;
  }
}
</script>
