<template>
  <div>
    <div class="type-image" v-if="this.$attrs.type === 'normal-label'"><img src="../../assets/pannon-garden.svg"/></div>
    <div class="type-image" v-if="this.$attrs.type === 'mediterranean-label'"><img src="../../assets/pannon-mediterran.svg"/></div>
  </div>
</template>


<script>

export default {
  name: 'Type',
  data(){
    return {
      type: null
    }
  }
};
</script>
