<template>
  <div class="tab-content" id="day_selector">
      <div class="tab-content-inner tab-content-inner-first p-4">
        <div class="row justify-content-between">
          <div class="col-10 col-md-auto">
            <h3 class="mb-3">{{ $t('select-day-label') }}</h3>
          </div>
          <div class="col-2 col-md-auto">
            <svg v-tooltip="$t('date-component-tooltip-label')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="24" height="24"
                 viewBox="0 0 24 24"
                 style=" fill:#000000;"> <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"></path></svg>
          </div>
        </div>
        <div class="row border-bottom mb-3 py-2">
          <div class="col-auto">
            <div class="form-group">
              <div class="checkbox-container">
                <input type="checkbox" style="display:none" id="custom_date" name="custom_date" class="inp-cbx" value="custom" v-model="customDate" v-on:change="loadTime()">
                <label class="cbx square" for="custom_date">
                  <span>
                    <svg viewBox="0 0 12 10">
                      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                    </svg>
                  </span>
                  <span>{{ $t('custom-date-label') }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-auto d-none">
            <div class="form-group">
              <div class="checkbox-container">
                <input type="checkbox" style="display:none" id="multi_pickup" name="multi_pickup" class="inp-cbx" value="multi" v-model="multiPickup">
                <label class="cbx square" for="multi_pickup">
                  <span>
                    <svg viewBox="0 0 12 10">
                      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                    </svg>
                  </span>
                  <span>{{ $t('multi-pickup-label') }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-show="customDate">
          <div class="col-12 col-md-4">
            <div class="form-group" :class="{ 'form-group-error': $v.order.customDate.$error }">
              <div class="input-box">
                <label for="customDate">{{ $t('select-date-label') }}</label>
                <input id="customDate" name="customDate" type="date" class="datepicker" onkeydown="return false" :min="mindDate" v-model="order.customDate" v-on:change="getTimeCustomDate()">
              </div>
              <div class="error">{{ $t('this-field-is-required-label') }}</div>
            </div>
          </div>
        </div>

        <div class="">
          <div class="datePicker">
            <div id="day-selector" class="date-selector" v-show="!customDate">
              <div :class="'row select-day select-day-'+index" v-for="(week,index) in days" :key="week.id">
                <div class="col-6 col-sm-4 col-md p-2 pb-3" v-for="day in week" :key="day.id">
                  <label :for="'day_'+day.date" v-bind:class="day.is_bookable.day ? 'w-100' : 'w-100 disabled'">
                    <input :id="'day_'+day.date" name="selectedDay"  type="radio" class="dayInput" :value="day.date" v-model="order.selectedDay" :disabled="day.is_bookable.day === false" v-on:change="getTime()">
                    <div v-bind:class="day.is_bookable.day ? 'day' : 'day disabled'">
                      <div class="row">
                        <div class="col-12 col-xl-6 dayname">{{ day.name }}</div>
                        <div class="col-12 col-xl-6 daydate text-xl-right">{{ changeDate(day.date) }}</div>
                        <div class="col-12 py-1">
                          <span class="badge badge-rounded badge-success font-weight-bold morning-sign" v-show="day.is_bookable.morning">
                              {{ $t('morning-label') }}
                          </span>
                          <span class="badge badge-rounded badge-grey font-weight-bold morning-sign" v-show="!day.is_bookable.morning">
                              {{ $t('morning-label') }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    <div class="tab-content-inner p-4 mt-4">
      <div class="time-selector overflow-hidden" id="carouselExampleControls" v-show="times && this.$parent.order.delivery_type != 'delivery-label'">
        <div class="row">
          <div class="col-12 col-sm-6">
            <h5 class="mb-4" >{{ $t('pickup-time-label') }}</h5>
            <p class="text-muted text-small mb-0"></p>
          </div>
          <div class="col-12 col-sm-6">
            <div class="row justify-content-end d-none d-md-flex d-lg-none">
              <div class="col-auto timeselector-arrows">
                <a href="#carouselExampleControls" ref="arrowLeft" data-slide="prev" class="btn btn-light btn-square text-decoration-none time-selector-slider-arrow arrow-left"><i class="icon-carousel-left"></i></a>
                <a href="#carouselExampleControls" ref="arrowRight" data-slide="prev" class="btn btn-light btn-square text-decoration-none time-selector-slider-arrow arrow-right"><i class="icon-carousel-right"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-auto my-auto">
          <div class="deliveryTimeSelector" v-touch:swipe="swipeHandler">
            <ul class="slider-row" role="listbox">
              <li class="slider-col" :class="{ 'active': index === 0 && time.is_available }" v-for="(time,index) in times" v-bind:key="time.id">
                <input type="radio" class="timeSelector" :id="'time-'+time.carbon_time" :name="'time'" :value="time.hour" :disabled="!time.is_available" v-model="order.time">
                <label :for="'time-'+time.carbon_time" class="time">
                  <div :class="time.is_available ? 'status status-success' : 'status status-danger'"></div>
                  <p class="font-weight-medium mb-1 time-text font-size-12">
                    {{time.hour}}
                  </p>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
        <div class="tab-content-inner">
          <div class="preferred-time" v-show="this.$parent.order.delivery_type == 'delivery-label'">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-3 px-2">
                <div class="form-group">
                  <div class="select-box compact">
                    <label for="time">{{ $t('preferred-time-label') }}</label>
                    <Select2 id="time" v-model="order.time" :options="preferredTimes" />
                  </div>
                  <div class="error">{{ $t('please-select-label') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3 py-2" v-show="multiPickup">
          <div class="col-12">
            <div class="accordion w-100 " id="slot01">
              <div class="card">
                <div class="card-header mb-0" id="slot01-inner">
                  <h2 class="mb-0">
                    <button class="btn btn-block text-left font-weight-medium line-height-1h" type="button" data-toggle="collapse" data-target="#slot01-body" aria-expanded="false" aria-controls="slot01">
                      <div class="row d-flex align-items-center">
                        <div class="col-auto">
                          <p class="mb-0">Slot #1</p>
                        </div>
                        <div class="col-auto">
                          <p class="text-muted font-size-14 mb-0">40 Pallets</p>
                        </div>
                        <div class="col-auto">
                          <p class="text-muted font-size-14 mb-0">01-06-2020</p>
                        </div>
                        <div class="col-auto">
                          <p class="text-muted font-size-14 mb-0">14:00</p>
                        </div>
                        <div class="col">
                          <div class="btn btn-pre-square text-decoration-none font-size-12 font-weight-medium p-0 float-right">
                            <i class="icon-dropdown text-green"></i>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                </div>
                <div id="slot01-body" class="slotbody collapse " aria-labelledby="slot01-inner" data-parent="#slot01">
                  <div class="card-body">
                    <div class="col-auto">
                      <p>Pallets & Pickup Time<p>
                      <div class="form-group pickup-time">
                        <div class="input-box">
                          <label for="roll_size">Rolls</label>
                          <div class="input-group">
                            <input type="text" id="roll_size" name="roll_size" class="form-control">
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import { requiredIf} from "vuelidate/lib/validators";
import moment from "moment";
const axios = require('axios').default;

export default {
  data: function () {
    return {
      customDate: false,
      multiPickup: false,
      mindDate: null,
      days: null,
      times: null,
      preferredTimes: [
        {id: '08:00',text:'08:00'},
        {id: '08:30',text:'08:30'},
        {id: '09:00',text:'09:00'},
        {id: '09:30',text:'09:30'},
        {id: '10:00',text:'10:00'},
        {id: '10:30',text:'10:30'},
        {id: '11:00',text:'11:00'},
        {id: '11:30',text:'11:30'},
        {id: '12:00',text:'12:00'},
        {id: '12:30',text:'12:30'},
        {id: '13:00',text:'13:00'},
        {id: '13:30',text:'13:30'},
        {id: '14:00',text:'14:00'},
        {id: '14:30',text:'14:30'},
        {id: '15:00',text:'15:00'},
        {id: '15:30',text:'15:30'},
        {id: '16:00',text:'16:00'},
        {id: '16:30',text:'16:30'},
        {id: '17:00',text:'17:00'}
      ],
      order:{
        customDate: this.$parent.order.pickup_time ? moment(this.$parent.order.pickup_time).format('YYYY-MM-DD') : null,
        selectedDay: this.$parent.order.pickup_time ? moment(this.$parent.order.pickup_time).format('YYYY-MM-DD') : null,
        time: this.$parent.order.pickup_time ? moment(this.$parent.order.pickup_time).format('HH:ss') : null
      }
    }
  },
  validations: {
    order:{
      customDate: {
        required: requiredIf(function(){
          return this.customDate
        })
      },
      /*time: {
        required: requiredIf(function(){
          return this.order.customDate || this.order.selectedDay
        })
      }*/
    }
  },
  components: {
  },
  methods:{
    swipeHandler(direction){
      if(direction == 'left'){
        this.$refs.arrowRight.click()
      }
      if(direction == 'right'){
        this.$refs.arrowLeft.click()
      }
    },
    validate(){
      const self = this;

      self.$v.$touch()

      if (!self.$v.$invalid) {

        if(self.customDate){
          self.order.selectedDay = false;
          if(self.order.customDate){
            self.$parent.order.pickup_time = moment(self.order.customDate).format('YYYY-MM-DD');
          }else{
            self.$swal('Please select date');
            return false;
          }

          if(self.order.time){
            self.$parent.order.pickup_time = moment(self.order.customDate).format('YYYY-MM-DD') + ' ' +self.order.time;
          }else{
            self.$swal('Please select pickup time');
            return false;
          }
        }else{
          self.order.customDate = false;

          if(self.order.selectedDay){
            self.$parent.order.pickup_time = moment(self.order.selectedDay).format('YYYY-MM-DD');
          }else{
            self.$swal('Please select a day');
            return false;
          }

          if(self.order.time){
            self.$parent.order.pickup_time = moment(self.order.selectedDay).format('YYYY-MM-DD') + ' ' +self.order.time;
          }else{
            self.$swal('Please select pickup time');
            return false;
          }
        }
        self.$parent.currentStep++;
        window.scrollTo(0,0);
      }
    },
    getCalendar(){
      const self = this;
      self.days = null;

      axios.get(process.env.VUE_APP_API_URL+'calendar', {
        device_name: process.env.VUE_APP_DEVICE,
        params:{
          locale: self.$i18n.locale,
          amount: self.$parent.order.total_area
        },
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        self.days = response.data
      }).catch(function (error) {
        self.$swal(error.message);
      });
    },
    getTime(){
      const self = this;
      self.times = [];
      //self.order.time = null;

      axios.get(process.env.VUE_APP_API_URL+'times', {
        device_name: process.env.VUE_APP_DEVICE,
        params:{
          order_id: self.$parent.order.id,
          language: self.$i18n.locale,
          amount: self.$parent.order.total_area,
          day: moment(self.order.selectedDay).format('YYYY-MM-DD')
        },
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        self.times = response.data;
        self.times.map(function (time){
          if(!time.is_available && moment(self.$parent.order.pickup_time).format('HH:ss') === time.hour){
            self.order.time = null
          }
        })
        //self.order.time = self.$parent.order.pickup_time && self ? moment(this.$parent.order.pickup_time).format('HH:ss') : null
      }).catch(function (error) {
        self.$swal(error.message);
      });
    },

    loadTime(){
      const self = this;
      self.times = [];
      self.order.time = null;
      if( (self.customDate && self.order.customDate) || (!self.customDate && self.order.selectedDay)) {

        const selectedTime = self.customDate ? self.order.customDate : self.order.selectedDay;

        axios.get(process.env.VUE_APP_API_URL + 'times', {
          device_name: process.env.VUE_APP_DEVICE,
          params: {
            order_id: self.$parent.order.id,
            language: self.$i18n.locale,
            amount: self.$parent.order.total_area,
            day: moment(selectedTime).format('YYYY-MM-DD')
          },
          headers: {
            'Authorization': `Bearer ${localStorage.token}`
          }
        }).then(function (response) {
          self.times = response.data;
          //self.order.time = self.$parent.order.pickup_time ? moment(this.$parent.order.pickup_time).format('HH:ss') : null
        }).catch(function (error) {
          self.$swal(error.message);
        });
      }
    },

    getTimeCustomDate(){
      const self = this;
      self.times = [];
      self.order.time = null;

      axios.get(process.env.VUE_APP_API_URL+'times', {
        device_name: process.env.VUE_APP_DEVICE,
        params:{
          language: self.$i18n.locale,
          amount: self.$parent.order.total_area,
          day: moment(self.order.customDate).format('YYYY-MM-DD')
        },
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        self.times = response.data;
        //self.order.time = self.$parent.order.pickup_time ? moment(this.$parent.order.pickup_time).format('HH:ss') : null
      }).catch(function (error) {
        self.$swal(error.message);
      });
    },
    changeDate(date){
      return moment(date).format('MM-DD')
    },
    initDatePicker(){
      const self = this;

      var dt = new Date();
      dt.setDate(dt.getDate() + 21);

      var minDate = moment(dt).format('YYYY-MM-DD')

      if(self.$parent.order.pickup_time >= minDate){
        self.customDate = true;
      }else{
        self.customDate = false;
      }
    }
  },
  beforeMount() {
    if(this.$parent.order.pickup_time) {
      this.initDatePicker();
      this.getTime();
      //this.order.time = moment(this.$parent.order.pickup_time).format('HH:ss')
    }
  },
  mounted() {
    var dt = new Date();
    dt.setDate(dt.getDate() + 21);

    this.mindDate = moment(dt).format('YYYY-MM-DD')

    this.getCalendar();
  },
}
</script>
